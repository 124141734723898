(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbErrorHandler.service:ResponseHandlerInterceptor
   *
   * @description
   *
   */
  angular
    .module('wbErrorHandler')
    .service('ResponseHandlerInterceptor', ResponseHandlerInterceptor);

  function ResponseHandlerInterceptor(ResponseHandler, $injector, $q, $document, $translate, _, $log) {
    var responseHandlers = [
        new ResponseHandler({
          code: /^0$/g,
          message: 'errorHandler'
        }),
        new ResponseHandler({
          code: /^404$/g
        }),
        new ResponseHandler({
          code: /^5..$/g,
          message: 'errorHandler.response.5xx'
        }),
        new ResponseHandler({
          code: /^40[1|3]$/g,
          action: function ($injector) {
            /*eslint no-shadow:0*/
            var Auth = $injector.get('Auth')
              , $state = $injector.get('$state')
              , OrderService = $injector.get('OrderService')
              , SessionService = $injector.get('SessionService');

            Auth.invalidateSession();
            OrderService.clearStorageItems();
            SessionService.clearSessionTimeout();
            $state.go('login');
          }
        }),
        new ResponseHandler({
          code: /^400$/g,
          action: function ($injector) {
            /*eslint no-shadow:0*/
            var $log = $injector.get('$log');
            $log.debug('Backend validation error triggered');
          }
        })
      ],
      fallbackHandler = new ResponseHandler({
        message: 'errorHandler'
      });

    this.response = function (response) {
      return response;
    };

    function onResponseError(response) {
      var handler = _.find(responseHandlers, function (responseHandler) {
        return responseHandler.isResponsive(response);
      });

      if (!handler) {
        $log.debug('could not find responseHandler for this response error: ', response);
        handler = fallbackHandler;
      }
      handler.action(response);

      if (!handler.message) {
        return;
      }
      // we need use $injector.get on async translate callback to prevent circular dependency with $mdDialog and $httpProvider
      $translate([handler.message + '.title', handler.message + '.content', 'errorHandler.button.ok'])
        .then(function (translations) {
          var $mdDialog = $injector.get('$mdDialog');
          $mdDialog.show(
            $mdDialog.alert({focusOnOpen: false})
              .parent(angular.element($document.body))
              .title(translations[handler.message + '.title'])
              .content(translations[handler.message + '.content'])
              .ariaLabel(translations[handler.message + '.title'])
              .ok(translations['errorHandler.button.ok'])
          );
        });
    }

    this.responseError = function (response) {
      onResponseError(response);
      return $q.reject(response);
    };
  }
}());
